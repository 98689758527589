import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import asides from '../../routes/asideRoutes';

const SentryRoutes = withSentryReactRouterV6Routing(Routes);
const AsideRoutes = () => {
	return (
		<SentryRoutes>
			{asides.map((page) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Route key={page.path} {...page} />
			))}
		</SentryRoutes>
	);
};

export default AsideRoutes;
