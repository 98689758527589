import React, { useCallback, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import Icon from './icon/Icon';
import Input from './bootstrap/forms/Input';
import { useSearchContext } from '../contexts/searchContext';

const Search = ({ isDisable }) => {
	const { setSearch } = useSearchContext();
	const refSearchInput = useRef(null);
	const timeout = useRef();
	const formik = useFormik({
		initialValues: {
			searchInput: '',
		},
	});

	const loadData = useCallback(() => {
		setSearch(formik.values.searchInput);
	}, [formik.values.searchInput, setSearch]);

	// Debounce fetch queries
	useEffect(() => {
		refSearchInput?.current?.focus();
		clearTimeout(timeout.current);
		timeout.current = setTimeout(loadData, 500);
	}, [formik.values.searchInput, loadData]);

	return (
		<div className='d-flex align-items-center'>
			<label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
				<Icon icon='Search' size='2x' color='primary' />
			</label>
			<Input
				id='searchInput'
				type='search'
				className='border-0 shadow-none bg-transparent'
				placeholder='Поиск...'
				onChange={formik.handleChange}
				value={formik.values.searchInput}
				autoComplete='off'
				disabled={isDisable}
			/>
		</div>
	);
};

Search.propTypes = {
	isDisable: PropTypes.bool,
};

Search.defaultProps = {
	isDisable: false,
};

export default Search;
