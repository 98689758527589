import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSessionStorage } from 'react-use';

const DevContext = createContext(null);

export const DevContextProvider = ({ children }) => {
	const [isDev, setIsDev] = useSessionStorage('isDev', false);
	const value = useMemo(
		() => ({
			isDev,
			setIsDev,
		}),
		[isDev, setIsDev],
	);

	return <DevContext.Provider value={value}>{children}</DevContext.Provider>;
};

export const useDevContext = () => useContext(DevContext);

DevContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
