import React from 'react';
import PropTypes from 'prop-types';

import PageWrapper from '../PageWrapper/PageWrapper';
import Page from '../Page/Page';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Alert, { AlertHeading } from '../../components/bootstrap/Alert';
import Button from '../../components/bootstrap/Button';

const FallBack = ({ error }) => (
	<PageWrapper>
		<Page container='fluid'>
			<div className='row h-100'>
				<div className='col-12'>
					<Card stretch>
						<CardBody className='d-flex align-items-center'>
							<Alert color='danger' icon='ExclamationCircleFill' className='w-100' isLight>
								<div className='d-flex justify-content-between align-items-center'>
									<div>
										<AlertHeading>Ошибка при загрузке страницы</AlertHeading>
										{error ? <p>{error.message}</p> : null}
									</div>
									<Button
										color='success'
										icon='ArrowClockwise'
										onClick={() => window.location.reload(true)}>
										Перезагрузить
									</Button>
								</div>
							</Alert>
						</CardBody>
					</Card>
				</div>
			</div>
		</Page>
	</PageWrapper>
);

FallBack.propTypes = {
	error: PropTypes.shape({
		message: PropTypes.string,
	}),
};

FallBack.defaultProps = {
	error: null,
};

export default FallBack;
