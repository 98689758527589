import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
import classNames from 'classnames';

import Icon from './icon/Icon';
import { AvatarStorage } from './Avatar';
import Spinner from './bootstrap/Spinner';
import Input from './bootstrap/forms/Input';

import useFetch from '../hooks/useFetch';
import { useAuth } from '../contexts/authContext';
import useDarkMode from '../hooks/useDarkMode';
import userType from '../types/User';
import FileIcon from './extras/FileIcon';

const agreementType = PropTypes.shape({
	id: PropTypes.string,
	parentid: PropTypes.string,
	typeid: PropTypes.string,
	name: PropTypes.string,
	content: PropTypes.string,
	user_id: PropTypes.string,
	status: PropTypes.string,
	visible: PropTypes.string,
	hash: PropTypes.string,
	planned_at: PropTypes.string,
	created_at: PropTypes.string,
	updated_at: PropTypes.string,
});

const projectType = PropTypes.shape({
	id: PropTypes.string,
	parentid: PropTypes.string,
	typeid: PropTypes.string,
	name: PropTypes.string,
	content: PropTypes.string,
	user_id: PropTypes.string,
	status: PropTypes.string,
	visible: PropTypes.string,
	hash: PropTypes.string,
	planned_at: PropTypes.string,
	created_at: PropTypes.string,
	updated_at: PropTypes.string,
});

const taskType = PropTypes.shape({
	id: PropTypes.string,
	parentid: PropTypes.string,
	typeid: PropTypes.string,
	name: PropTypes.string,
	content: PropTypes.string,
	user_id: PropTypes.string,
	status: PropTypes.string,
	visible: PropTypes.string,
	hash: PropTypes.string,
	planned_at: PropTypes.string,
	created_at: PropTypes.string,
	updated_at: PropTypes.string,
});

const fileType = PropTypes.shape({
	uid: PropTypes.string,
	agreementid: PropTypes.string,
	categoryid: PropTypes.string,
	name: PropTypes.string,
	content: PropTypes.string,
	format: PropTypes.string,
	version: PropTypes.string,
	filename: PropTypes.string,
	storage: PropTypes.string,
	path: PropTypes.string,
	size: PropTypes.string,
	status: PropTypes.string,
	visible: PropTypes.string,
	hash: PropTypes.string,
	hash_file: PropTypes.string,
	created_at: PropTypes.string,
	updated_at: PropTypes.string,
});

const mock = {
	module: 'agreements',
	item: {
		uid: '420',
		agreementid: '137',
		categoryid: '0',
		name: 'agreements (13).xlsx',
		content: '',
		format: 'xlsx',
		version: '0000000001',
		filename: 'agreements_13.xlsx',
		storage: 'cloud',
		path: 'projects/115/1682/1683/1684/',
		size: '13433',
		status: '83',
		visible: '1',
		hash: 'a492c17cb34ef6ac0bbb026d9fe90f51',
		hash_file: null,
		created_at: '2023-01-24 18:31:56',
		updated_at: '2023-01-26 16:14:10',
	},
	user: {
		hash: 'aea4e7a7-dd55-11e6-ab43-1866dae744ad',
		name: '\u041a\u043e\u043d\u0441\u0442\u0430\u043d\u0442\u0438\u043d',
		secondname:
			'\u041a\u043e\u043d\u0441\u0442\u0430\u043d\u0442\u0438\u043d\u043e\u0432\u0438\u0447',
		surname: '\u041b\u0443\u0433\u043e\u0432\u043e\u0439',
		post: '',
		images: [
			{
				type: 'avatar',
				storage: 'old',
				path: '/upload/users/aea4e7a7-dd55-11e6-ab43-1866dae744ad.png',
				created_at: '2022-03-09 14:03:06',
				updated_at: '2023-02-02 15:02:00',
			},
		],
	},
	agreement: {
		id: '137',
		parentid: '0',
		typeid: '5',
		name: '\u0421\u043e\u0433\u043b\u0430\u0441\u043e\u0432\u0430\u043d\u0438\u0435 \u0444\u0430\u0439\u043b\u0430 agreements (13).xlsx \u043f\u0440\u043e\u0435\u043a\u0442\u0430 \u041d\u043e\u0432\u044b\u0439 \u0442\u0435\u0441\u0442\u043e\u0432\u044b\u0439 \u043f\u0440\u043e\u0435\u043a\u0442',
		content: 'dasdasd',
		user_id: '1',
		status: '71',
		visible: '1',
		hash: 'c87045febab4435e7b3c03857b62f118',
		planned_at: '2023-02-01 00:00:00',
		created_at: '2023-01-24 18:31:56',
		updated_at: null,
	},
};

const FileRow = ({ data, user }) => {
	const { darkModeStatus } = useDarkMode();

	return (
		<tr>
			<td>
				<FileIcon format={data.format} />
				<Link to={data.path}>{data.name}</Link>
			</td>
			<td>
				<AvatarStorage userName={`${user.surname} ${user.name}`} images={user.images} size={32} />
			</td>
			<td>{moment(data.created_at).format('LLL')}</td>
			<td>
				<Link
					to={data.path}
					className={classNames(
						'btn',
						{
							'btn-outline-dark': !darkModeStatus,
							'border-light': !darkModeStatus,
							'btn-light-dark': darkModeStatus,
						},
						'text-nowrap',
					)}>
					<Icon icon='Eye' className='me-2' />
					Подробнее
				</Link>
			</td>
		</tr>
	);
};

FileRow.propTypes = {
	data: fileType.isRequired,
	user: userType.isRequired,
};

const AgreementRow = ({ data }) => {
	const { darkModeStatus } = useDarkMode();

	return (
		<tr>
			<td>
				<Link to={`/agreements/${data.agreement.hash}`}>{data.agreement.name}</Link>
			</td>
			<td>
				<AvatarStorage
					userName={`${data.user.surname} ${data.user.name}`}
					images={data.user.images}
					size={32}
				/>
			</td>
			<td>{moment(data.agreement.created_at).format('LLL')}</td>
			<td>
				<Link
					to={`/agreements/${data.agreement.hash}`}
					className={classNames(
						'btn',
						{
							'btn-outline-dark': !darkModeStatus,
							'border-light': !darkModeStatus,
							'btn-light-dark': darkModeStatus,
						},
						'text-nowrap',
					)}>
					<Icon icon='Eye' className='me-2' />
					Подробнее
				</Link>
			</td>
		</tr>
	);
};

AgreementRow.propTypes = {
	data: PropTypes.shape({
		agreement: agreementType,
		user: userType,
	}).isRequired,
};

const ProjectRow = ({ data }) => {
	const { darkModeStatus } = useDarkMode();

	return (
		<tr>
			<td>
				<Link to={`/projects/${data.project.hash}`}>{data.project.name}</Link>
			</td>
			<td>
				<AvatarStorage
					userName={`${data.user.surname} ${data.user.name}`}
					images={data.user.images}
					size={32}
				/>
			</td>
			<td>{moment(data.project.created_at).format('LLL')}</td>
			<td>
				<Link
					to={`/projects/${data.project.hash}`}
					className={classNames(
						'btn',
						{
							'btn-outline-dark': !darkModeStatus,
							'border-light': !darkModeStatus,
							'btn-light-dark': darkModeStatus,
						},
						'text-nowrap',
					)}>
					<Icon icon='Eye' className='me-2' />
					Подробнее
				</Link>
			</td>
		</tr>
	);
};

ProjectRow.propTypes = {
	data: PropTypes.shape({
		project: projectType,
		user: userType,
	}).isRequired,
};

const TaskRow = ({ data }) => {
	const { darkModeStatus } = useDarkMode();

	return (
		<tr>
			<td>
				<Link to={`/tasks/${data.task.hash}`}>{data.task.name}</Link>
			</td>
			<td>
				<AvatarStorage
					userName={`${data.user.surname} ${data.user.name}`}
					images={data.user.images}
					size={32}
				/>
			</td>
			<td>{moment(data.task.created_at).format('LLL')}</td>
			<td>
				<Link
					to={`/tasks/${data.task.hash}`}
					className={classNames(
						'btn',
						{
							'btn-outline-dark': !darkModeStatus,
							'border-light': !darkModeStatus,
							'btn-light-dark': darkModeStatus,
						},
						'text-nowrap',
					)}>
					<Icon icon='Eye' className='me-2' />
					Подробнее
				</Link>
			</td>
		</tr>
	);
};

TaskRow.propTypes = {
	data: PropTypes.shape({
		task: taskType,
		user: userType,
	}).isRequired,
};

const types = {
	file: FileRow,
	agreements: AgreementRow,
	projects: ProjectRow,
	tasks: TaskRow,
};

const GetRow = ({ data }) => {
	const Node = types[data.module];
	// eslint-disable-next-line react/jsx-props-no-spreading
	return (
		<>
			{data.item && <FileRow data={data.item} user={data.user} />}
			<Node data={data} />
		</>
	);
};

GetRow.propTypes = {
	data: PropTypes.shape({
		module: PropTypes.string,
		item: fileType,
		agreement: agreementType,
		project: projectType,
		task: taskType,
		user: userType,
	}).isRequired,
};

const SearchModal = ({ isDisable }) => {
	const refSearchInput = useRef(null);
	const timeout = useRef();
	const formik = useFormik({
		initialValues: {
			searchInput: '',
		},
	});
	const { fetchData } = useFetch();
	const auth = useAuth();

	const setSubmitting = useMemo(() => formik.setSubmitting, [formik.setSubmitting]);

	const [results, setResults] = useState(null);

	const loadData = useCallback(() => {
		if (formik.values.searchInput && formik.values.searchInput.length >= 3) {
			setSubmitting(true);
			fetchData(`/search/?q=${formik.values.searchInput}&token=${auth.token}`)
				.then((responseData) => {
					if (responseData) {
						setResults(responseData);
					} else {
						setResults([mock]);
					}
				})
				.finally(() => {
					setSubmitting(false);
				});
		} else {
			setResults(null);
		}
	}, [auth.token, fetchData, formik.values.searchInput, setSubmitting]);

	// Debounce fetch queries
	useEffect(() => {
		refSearchInput?.current?.focus();
		clearTimeout(timeout.current);
		timeout.current = setTimeout(loadData, 500);
	}, [formik.values.searchInput, loadData]);

	return (
		<div className={`search-popup ${results?.length > 0 ? 'show' : ''} vstack gap-3`}>
			<div className='d-flex fw-bold fs-3'>
				<label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
					{formik.isSubmitting ? (
						<Spinner color='primary' />
					) : (
						<Icon icon='Search' size='2x' color='primary' />
					)}
				</label>
				<Input
					id='searchInput'
					type='search'
					className='border-0 shadow-none bg-transparent'
					placeholder='Поиск...'
					onChange={formik.handleChange}
					value={formik.values.searchInput}
					autoComplete='off'
					disabled={isDisable}
				/>
			</div>
			{results && (
				<div className='table-responsive'>
					<table className='table table-modern'>
						<thead>
							<tr>
								<th>Документ</th>
								<th>Пользователь</th>
								<th>Дата создания</th>
								<td />
							</tr>
						</thead>
						<tbody>
							{results.map((result) => (
								<GetRow module={result.module} data={result} key={result.item.hash} />
							))}
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

SearchModal.propTypes = {
	isDisable: PropTypes.bool,
};

SearchModal.defaultProps = {
	isDisable: false,
};

export default SearchModal;
