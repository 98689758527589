import PropTypes from 'prop-types';

import {
	string,
	object,
	array,
	boolean,
	record,
	pattern,
	optional,
	union,
	integer,
	nullable,
} from 'superstruct';

import { StatusData } from './Status';

export const UserData = object({
	blocked_at: optional(string()),
	contacts: array(
		object({
			type: string(),
			value: string(),
			comment: string(),
		}),
	),
	email: string(),
	gender: optional(string()),
	hash: string(),
	id: optional(union([pattern(string(), /\d+/), integer()])),
	images: nullable(
		array(
			object({
				type: string(),
				storage: string(),
				path: string(),
				created_at: string(),
				updated_at: string(),
			}),
		),
	),
	isAdmin: boolean(),
	name: string(),
	post: string(),
	roles: record(pattern(string(), /\d+/), string()),
	secondname: string(),
	surname: string(),
	status: optional(StatusData),
	telegram_id: optional(nullable(string())),
});

const userType = PropTypes.shape({
	hash: PropTypes.string,
	email: PropTypes.string,
	name: PropTypes.string,
	secondname: PropTypes.string,
	surname: PropTypes.string,
	post: PropTypes.string,
	images: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			storage: PropTypes.string,
			path: PropTypes.string,
			created_at: PropTypes.string,
			updated_at: PropTypes.string,
		}),
	),
	contacts: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			value: PropTypes.string,
			comment: PropTypes.string,
		}),
	),
	// eslint-disable-next-line react/forbid-prop-types
	roles: PropTypes.object,
	isAdmin: PropTypes.bool,
	telegram_id: PropTypes.string,
});

export default userType;
