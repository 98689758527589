import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FileIcon = forwardRef(({ format, className }, ref) => (
	<span
		className={classNames(
			'fiv-viv',
			'fiv-icon-blank',
			format ? `fiv-icon-${format.toLowerCase()}` : null,
			'fs-5',
			'me-2',
			className,
		)}
		ref={ref}
	/>
));

FileIcon.displayName = 'FileIcon';

FileIcon.propTypes = {
	format: PropTypes.string,
	className: PropTypes.string,
};

FileIcon.defaultProps = {
	format: '',
	className: null,
};

export default FileIcon;
