import React from 'react';
import classNames from 'classnames';

import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import useDarkMode from '../../../hooks/useDarkMode';
import Spinner from '../../../components/bootstrap/Spinner';
import Popovers from '../../../components/bootstrap/Popovers';
import Logo from '../../../components/Logo';
import CommonHeaderChat from './CommonHeaderChat';

import { useLoadingContext } from '../../../contexts/loadingContext';
import { useAuth } from '../../../contexts/authContext';

const DefaultHeader = () => {
	const { darkModeStatus } = useDarkMode();
	const { isLoading } = useLoadingContext();
	const auth = useAuth();

	return (
		<Header hasLeftMobileMenu={false}>
			<HeaderLeft>
				<div className='d-flex align-items-center'>
					<div className='row g-4'>
						<div className='col-md-auto'>
							<div className='brand'>
								<div className='brand-logo'>
									<h1 className='brand-title'>
										<Logo height={32} />
									</h1>
								</div>
							</div>
						</div>
						<div className='col-md-auto d-flex align-items-center'>
							<div
								className={classNames('fw-bold', {
									'text-dark': !darkModeStatus,
								})}>
								{isLoading ? (
									<Popovers title='Загрузка...' trigger='hover'>
										<Spinner />
									</Popovers>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</HeaderLeft>
			{auth.user && (
				<HeaderRight>
					<div className='row g-3'>
						<CommonHeaderChat />
					</div>
				</HeaderRight>
			)}
		</Header>
	);
};
// od0i8jvo
export default DefaultHeader;
