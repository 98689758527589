import UserImage from '../../assets/img/wanna/wanna1.png';
import UserImageWebp from '../../assets/img/wanna/wanna1.webp';
import UserImage2 from '../../assets/img/wanna/wanna2.png';
import UserImage2Webp from '../../assets/img/wanna/wanna2.webp';
import UserImage3 from '../../assets/img/wanna/wanna3.png';
import UserImage3Webp from '../../assets/img/wanna/wanna3.webp';
import UserImage4 from '../../assets/img/wanna/wanna4.png';
import UserImage4Webp from '../../assets/img/wanna/wanna4.webp';
import UserImage5 from '../../assets/img/wanna/wanna5.png';
import UserImage5Webp from '../../assets/img/wanna/wanna5.webp';
import UserImage6 from '../../assets/img/wanna/wanna6.png';
import UserImage6Webp from '../../assets/img/wanna/wanna6.webp';
import UserImage7 from '../../assets/img/wanna/wanna7.png';
import UserImage7Webp from '../../assets/img/wanna/wanna7.webp';
import SERVICES from './serviceDummyData';

import User7Landing from '../../assets/img/wanna/landing1.png';

export const user1 = {
	hash: 'aea4e7a7-dd55-11e6-ab43-1866dae744ad',
	email: 'lugovoy@lgprk.ru',
	name: 'Константин',
	secondname: 'Константинович',
	surname: 'Луговой',
	post: '',
	images: [
		{
			type: 'avatar',
			storage: 'old',
			path: '/upload/users/aea4e7a7-dd55-11e6-ab43-1866dae744ad.png',
			created_at: '2022-03-09 14:03:06',
			updated_at: '2023-02-14 11:02:00',
		},
	],
	contacts: [
		{
			type: 'email',
			value: 'lugovoy@lgprk.ru',
			comment: '',
		},
		{
			type: 'localphone',
			value: '2080',
			comment: '',
		},
	],
	roles: {
		2: 'Администратор',
		34: 'Задачи',
		35: 'Проекты',
		36: 'Права доступа к проекту',
		48: 'Создание новых проектов',
		49: 'Площадки',
		56: 'Документы',
		57: 'Контрагенты',
		61: 'Права доступа к контрагентам',
		67: 'Отчеты',
		68: 'Рабочее время',
		69: 'Согласования',
		94: 'Согласования',
		95: 'Проекты',
		96: 'Задачи',
	},
	isAdmin: true,
	blocked_at: '',
};

export const user2 = {
	hash: '7e1972335f5dfb2e3e793a9f3b5efb17',
	email: 'nsh@lgprk.ru',
	name: 'Николай',
	secondname: 'Валерьевич',
	surname: 'Шатов',
	post: 'Веб-мастер',
	images: [
		{
			type: 'avatar',
			storage: 'old',
			path: '/upload/users/7e1972335f5dfb2e3e793a9f3b5efb17.png',
			created_at: '2022-03-09 14:03:08',
			updated_at: '2023-01-23 12:01:01',
		},
	],
	contacts: [
		{
			type: 'email',
			value: 'nsh@lgprk.ru',
			comment: '',
		},
		{
			type: 'localphone',
			value: '3001',
			comment: '',
		},
	],
	roles: {
		2: 'Администратор',
		34: 'Задачи',
		35: 'Проекты',
		48: 'Создание новых проектов',
		56: 'Документы',
		57: 'Контрагенты',
		67: 'Отчеты',
		68: 'Рабочее время',
		69: 'Согласования',
		94: 'Согласования',
		95: 'Проекты',
		96: 'Задачи',
	},
	isAdmin: true,
};

const john = {
	id: 1,
	username: 'john',
	name: 'John',
	surname: 'Doe',
	secondname: 'Тестович',
	position: 'Сотрудник',
	department: 'Обособленное подразделение Шелепаново',
	src: UserImage,
	srcSet: UserImageWebp,
	isOnline: true,
	isReply: true,
	email: 'example@lgprk.ru',
	hash: '1',
	contacts: [
		{
			type: 'email',
			value: 'example@lgprk.ru',
		},
		{
			type: 'localphone',
			value: '2011',
		},
		{
			type: 'phone',
			value: '79991234567',
		},
	],
	color: 'primary',
	birthday: '1978-03-05',
	status: {
		name: 'Работает',
		color: 'success',
	},
	images: [
		{
			type: 'avatar',
			storage: 'new',
			path: UserImage,
		},
	],
	redirect: true,
	services: [SERVICES.SURFING, SERVICES.KITE_SURFING, SERVICES.TENNIS],
};

const grace = {
	id: 2,
	username: 'grace',
	name: 'Grace',
	surname: 'Buckland',
	secondname: 'Тестович',
	position: 'Подрядчик',
	department: 'Обособленное подразделение Шелепаново',
	src: UserImage2,
	srcSet: UserImage2Webp,
	isOnline: true,
	email: 'example@lgprk.ru',
	hash: '2',
	contacts: [
		{
			type: 'email',
			value: 'example@lgprk.ru',
		},
		{
			type: 'localphone',
			value: '2011',
		},
		{
			type: 'phone',
			value: '79991234567',
		},
	],
	color: 'warning',
	birthday: '1978-03-05',
	status: {
		name: 'Работает',
		color: 'success',
	},
	images: [
		{
			type: 'avatar',
			storage: 'new',
			path: UserImage2,
		},
	],
	redirect: false,
	services: [SERVICES.SNOWBOARDING, SERVICES.ICE_SKATING, SERVICES.KITE_SURFING],
};

const jane = {
	id: 3,
	username: 'jane',
	name: 'Jane',
	surname: 'Lee',
	secondname: 'Тестович',
	position: 'Подрядчик',
	department: 'Обособленное подразделение Шелепаново',
	src: UserImage3,
	srcSet: UserImage3Webp,
	isOnline: true,
	email: 'example@lgprk.ru',
	hash: '3',
	contacts: [
		{
			type: 'email',
			value: 'example@lgprk.ru',
		},
		{
			type: 'localphone',
			value: '2011',
		},
		{
			type: 'phone',
			value: '79991234567',
		},
	],
	color: 'secondary',
	birthday: '1978-03-05',
	status: {
		name: 'Работает',
		color: 'success',
	},
	images: [
		{
			type: 'avatar',
			storage: 'new',
			path: UserImage3,
		},
	],
	redirect: true,
	services: [SERVICES.YOGA, SERVICES.HANDBALL, SERVICES.CRICKET],
};

const ryan = {
	id: 4,
	username: 'ryan',
	name: 'Ryan',
	surname: 'McGrath',
	secondname: 'Тестович',
	position: 'Сотрудник',
	department: 'Обособленное подразделение Шелепаново',
	src: UserImage4,
	srcSet: UserImage4Webp,
	isOnline: false,
	email: 'example@lgprk.ru',
	hash: '4',
	contacts: [
		{
			type: 'email',
			value: 'example@lgprk.ru',
		},
		{
			type: 'localphone',
			value: '2011',
		},
		{
			type: 'phone',
			value: '79991234567',
		},
	],
	color: 'info',
	birthday: '1978-03-05',
	status: {
		name: 'Работает',
		color: 'success',
	},
	images: [
		{
			type: 'avatar',
			storage: 'new',
			path: UserImage4,
		},
	],
	redirect: false,
	services: [SERVICES.HIKING, SERVICES.FOOTBALL, SERVICES.HANDBALL],
};

const ella = {
	id: 5,
	username: 'ella',
	name: 'Ella',
	surname: 'Oliver',
	secondname: 'Тестович',
	position: 'Сотрудник',
	department: 'Обособленное подразделение Шелепаново',
	src: UserImage5,
	srcSet: UserImage5Webp,
	isOnline: false,
	email: 'example@lgprk.ru',
	hash: '5',
	contacts: [
		{
			type: 'email',
			value: 'example@lgprk.ru',
		},
		{
			type: 'localphone',
			value: '2011',
		},
		{
			type: 'phone',
			value: '79991234567',
		},
	],
	color: 'success',
	birthday: '1978-03-05',
	status: {
		name: 'Работает',
		color: 'success',
	},
	images: [
		{
			type: 'avatar',
			storage: 'new',
			path: UserImage5,
		},
	],
	redirect: true,
	services: [SERVICES.ICE_SKATING, SERVICES.TENNIS, SERVICES.SNOWBOARDING, SERVICES.YOGA],
};

const chloe = {
	id: 6,
	username: 'chloe',
	name: 'Chloe',
	surname: 'Walker',
	secondname: 'Тестович',
	position: 'Подрядчик',
	department: 'Обособленное подразделение Шелепаново',
	src: UserImage6,
	srcSet: UserImage6Webp,
	isOnline: true,
	email: 'example@lgprk.ru',
	phone: '79991234567',
	hash: '6',
	contacts: [
		{
			type: 'email',
			value: 'example@lgprk.ru',
		},
		{
			type: 'localphone',
			value: '2011',
		},
		{
			type: 'phone',
			value: '79991234567',
		},
	],
	color: 'warning',
	birthday: '1978-03-05',
	status: {
		name: 'Работает',
		color: 'success',
	},
	redirect: false,
	notifications: [
		{
			id: 1,
			type: 'info',
			content: 'Тестовое уведомление',
		},
	],
	images: [
		{
			type: 'avatar',
			storage: 'new',
			path: UserImage6,
		},
	],
	tasks: {
		new: 3,
		process: 4,
	},
	services: [SERVICES.VOLLEYBALL, SERVICES.CRICKET],
};

const sam = {
	id: 7,
	username: 'sam',
	name: 'Sam',
	surname: 'Roberts',
	secondname: 'Тестович',
	position: 'Сотрудник',
	department: 'Обособленное подразделение Шелепаново',
	src: UserImage7,
	srcSet: UserImage7Webp,
	isOnline: false,
	email: 'example@lgprk.ru',
	hash: '7',
	contacts: [
		{
			type: 'email',
			value: 'example@lgprk.ru',
		},
		{
			type: 'localphone',
			value: '2011',
		},
		{
			type: 'phone',
			value: '79991234567',
		},
	],
	color: 'danger',
	birthday: '1978-03-05',
	status: {
		name: 'Работает',
		color: 'success',
	},
	redirect: true,
	notifications: [
		{
			id: 1,
			type: 'info',
			content: 'Тестовое уведомление',
		},
	],
	images: [
		{
			type: 'avatar',
			storage: 'new',
			path: UserImage7,
		},
	],
	tasks: {
		new: 3,
		process: 4,
	},
	services: [],
	fullImage: User7Landing,
};

const USERS = {
	JOHN: john,
	GRACE: grace,
	JANE: jane,
	RYAN: ryan,
	ELLA: ella,
	CHLOE: chloe,
	SAM: sam,
};

export function getUserDataWithUsername(username) {
	return USERS[Object.keys(USERS).filter((f) => USERS[f].username.toString() === username)];
}

export function getUserDataWithId(id) {
	return USERS[Object.keys(USERS).filter((f) => USERS[f].id.toString() === id.toString())];
}

export default USERS;
