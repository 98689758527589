import React, { createContext, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const LoadingContext = createContext(null);

export const LoadingContextProvider = ({ children }) => {
	const [isLoading, setIsLoading] = useState(false);
	const value = useMemo(
		() => ({
			isLoading,
			setIsLoading,
		}),
		[isLoading, setIsLoading],
	);

	return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
};

export const useLoadingContext = () => useContext(LoadingContext);

LoadingContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
