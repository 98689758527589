import React from 'react';
import { demoPages, mainMenu, administrationMenu, libraryMenu } from '../menu';
import Aside from '../layout/Aside/Aside';

// const presentation = [
// 	/**
// 	 * Landing
// 	 */
// 	{
// 		path: dashboardMenu.dashboard.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: dashboardMenu.dashboardBooking.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: dashboardMenu.summary.path,
// 		element: <Aside />,
// 		exact: true,
// 	},

// 	/** ************************************************** */

// 	/**
// 	 * Pages
// 	 */

// 	/**
// 	 * Single Pages
// 	 */
// 	{
// 		path: demoPages.singlePages.subMenu.boxedSingle.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: demoPages.singlePages.subMenu.fluidSingle.path,
// 		element: <Aside />,
// 		exact: true,
// 	},

// 	/**
// 	 * List
// 	 */
// 	{
// 		path: demoPages.listPages.subMenu.listBoxed.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: demoPages.listPages.subMenu.listFluid.path,
// 		element: <Aside />,
// 		exact: true,
// 	},

// 	/**
// 	 * Grid
// 	 */
// 	{
// 		path: demoPages.gridPages.subMenu.gridBoxed.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: demoPages.gridPages.subMenu.gridFluid.path,
// 		element: <Aside />,
// 		exact: true,
// 	},

// 	/**
// 	 * Edit
// 	 */
// 	{
// 		path: demoPages.editPages.subMenu.editBoxed.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: demoPages.editPages.subMenu.editFluid.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: demoPages.editPages.subMenu.editWizard.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: demoPages.editPages.subMenu.editInCanvas.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: demoPages.editPages.subMenu.editInModal.path,
// 		element: <Aside />,
// 		exact: true,
// 	},

// 	{
// 		path: demoPages.pricingTable.path,
// 		element: <Aside />,
// 		exact: true,
// 	},

// 	/**
// 	 * END - Pages
// 	 */

// 	/**
// 	 * Auth Page
// 	 */
// 	{
// 		path: demoPages.page404.path,
// 		element: null,
// 		exact: true,
// 	},
// 	{
// 		path: demoPages.login.path,
// 		element: null,
// 		exact: true,
// 	},

// 	/**
// 	 * App
// 	 */

// 	/**
// 	 * App > Project Management
// 	 */
// 	{
// 		path: demoPages.projectManagement.subMenu.list.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: `${demoPages.projectManagement.subMenu.itemID.path}/:id`,
// 		element: <Aside />,
// 		exact: true,
// 	},

// 	/**
// 	 * App > Knowledge
// 	 */
// 	{
// 		path: demoPages.knowledge.subMenu.grid.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: `${demoPages.knowledge.subMenu.itemID.path}/:id`,
// 		element: <Aside />,
// 		exact: true,
// 	},

// 	/**
// 	 * App > Sales
// 	 */
// 	{
// 		path: demoPages.sales.subMenu.transactions.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: demoPages.sales.subMenu.salesList.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: demoPages.sales.subMenu.productsGrid.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: `${demoPages.sales.subMenu.productID.path}/:id`,
// 		element: <Aside />,
// 		exact: true,
// 	},

// 	/**
// 	 * App > Appointment
// 	 */
// 	{
// 		path: demoPages.appointment.subMenu.calendar.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: demoPages.appointment.subMenu.employeeList.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: `${demoPages.appointment.subMenu.employeeID.path}/:id`,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: demoPages.appointment.subMenu.appointmentList.path,
// 		element: <Aside />,
// 		exact: true,
// 	},

// 	/**
// 	 * App > CRM
// 	 */
// 	{
// 		path: demoPages.crm.subMenu.dashboard.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: demoPages.crm.subMenu.customersList.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: `${demoPages.crm.subMenu.customerID.path}/:id`,
// 		element: <Aside />,
// 		exact: true,
// 	},

// 	/**
// 	 * App > Chat
// 	 */
// 	{
// 		path: demoPages.chat.subMenu.withListChat.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: demoPages.chat.subMenu.onlyListChat.path,
// 		element: <Aside />,
// 		exact: true,
// 	},

// 	/**
// 	 * END - App
// 	 */

// 	/** ************************************************** */

// 	/**
// 	 * Page Layout Types
// 	 */
// 	{
// 		path: layoutMenu.blank.path,
// 		element: null,
// 		exact: true,
// 	},
// 	{
// 		path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: layoutMenu.pageLayout.subMenu.onlySubheader.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: layoutMenu.pageLayout.subMenu.onlyContent.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: layoutMenu.asideTypes.subMenu.defaultAside.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: layoutMenu.asideTypes.subMenu.minimizeAside.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// ];
// const documentation = [
// 	/**
// 	 * Bootstrap
// 	 */

// 	/**
// 	 * Content
// 	 */
// 	{
// 		path: componentsMenu.content.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.content.subMenu.typography.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.content.subMenu.images.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.content.subMenu.tables.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.content.subMenu.figures.path,
// 		element: <Aside />,
// 		exact: true,
// 	},

// 	/**
// 	 * Forms
// 	 */
// 	{
// 		path: componentsMenu.forms.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.forms.subMenu.formGroup.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.forms.subMenu.formControl.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.forms.subMenu.select.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.forms.subMenu.checksAndRadio.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.forms.subMenu.range.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.forms.subMenu.inputGroup.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.forms.subMenu.validation.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.forms.subMenu.wizard.path,
// 		element: <Aside />,
// 		exact: true,
// 	},

// 	/**
// 	 * Components
// 	 */
// 	{
// 		path: componentsMenu.components.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.tooltip.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.toasts.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.scrollspy.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.carousel.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.spinners.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.listGroup.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.breadcrumb.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.collapse.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.pagination.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.progress.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.card.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.button.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.buttonGroup.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.alert.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.badge.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.popovers.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.dropdowns.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.accordion.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.modal.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.navsTabs.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.offcanvas.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.components.subMenu.table.path,
// 		element: <Aside />,
// 		exact: true,
// 	},

// 	/**
// 	 * Utilities
// 	 */
// 	{
// 		path: componentsMenu.utilities.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.api.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.background.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.borders.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.colors.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.display.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.flex.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.float.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.interactions.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.overflow.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.position.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.shadows.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.sizing.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.spacing.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.text.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.verticalAlign.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.utilities.subMenu.visibility.path,
// 		element: <Aside />,
// 		exact: true,
// 	},

// 	/**
// 	 * Extra
// 	 */

// 	/**
// 	 * Icons
// 	 */
// 	{
// 		path: componentsMenu.icons.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.icons.subMenu.icon.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.icons.subMenu.material.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.icons.subMenu.bootstrapIcon.path,
// 		element: <Aside />,
// 		exact: true,
// 	},

// 	/**
// 	 * Charts
// 	 */
// 	{
// 		path: componentsMenu.charts.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsUsage.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsSparkline.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsLine.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsArea.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsColumn.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsBar.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsMixed.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsTimeline.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsCandleStick.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsBoxWhisker.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsPieDonut.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsRadar.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsPolar.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsRadialBar.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsBubble.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsScatter.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsHeatMap.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.charts.subMenu.chartsTreeMap.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.notification.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// 	{
// 		path: componentsMenu.hooks.path,
// 		element: <Aside />,
// 		exact: true,
// 	},
// ];

const auth = [
	/**
	 * Auth Page
	 */
	{
		path: demoPages.auth.path,
		element: null,
		exact: true,
	},
	{
		path: demoPages.page404.path,
		element: null,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: null,
		exact: true,
	},
	{
		path: demoPages.signUp.path,
		element: null,
		exact: true,
	},
	{
		path: `${demoPages.signUp.path}/:hash`,
		element: null,
		exact: true,
	},
];

const main = [
	{
		path: mainMenu.tasks.path,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.tasks.path}/new`,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.tasks.path}/:id`,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.tasks.path}/:hash/files/:file`,
		element: <Aside />,
		exact: true,
	},
	{
		path: mainMenu.dashboard.path,
		element: <Aside />,
		exact: true,
	},
	{
		path: mainMenu.book.path,
		element: <Aside />,
		exact: true,
	},
	{
		path: mainMenu.projects.path,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.projects.path}/:project`,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.projects.path}/:project/files`,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.projects.path}/:project/files/:hash`,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.projects.path}/:project/files/:hash/:file`,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.projects.path}/:project/files/:hash/:file/view`,
		element: <Aside />,
		exact: true,
	},
	{
		path: `/share/:key/${mainMenu.projects.path}`,
		element: null,
		exact: true,
	},
	{
		path: `/share/:key/${mainMenu.projects.path}/:project`,
		element: null,
		exact: true,
	},
	{
		path: `/share/:key/${mainMenu.projects.path}/:project/:hash`,
		element: null,
		exact: true,
	},
	{
		path: `/share/:key/${mainMenu.projects.path}/:project/:hash/view`,
		element: null,
		exact: true,
	},
	{
		path: `${mainMenu.projects.path}/:project/documents`,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.projects.path}/:project/documents/:hash`,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.projects.path}/:project/documents/:hash/:file`,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.projects.path}/:project/documents/:hash/:file/view`,
		element: <Aside />,
		exact: true,
	},
	{
		path: mainMenu.agreements.path,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.agreements.path}/:hash`,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.agreements.path}/:hash/files/:file`,
		element: <Aside />,
		exact: true,
	},
	{
		path: mainMenu.documents.subMenu.contractors.path,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.documents.subMenu.contractors.path}/:inn`,
		element: <Aside />,
		exact: true,
	},
	{
		path: mainMenu.documents.subMenu.docs.path,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.documents.subMenu.docs.path}/:category`,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.documents.subMenu.docs.path}/:category/:document`,
		element: <Aside />,
		exact: true,
	},
	{
		path: mainMenu.fleet.path,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.fleet.path}/vehicles/:hash`,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${mainMenu.fleet.path}/fines/:hash`,
		element: <Aside />,
		exact: true,
	},
	{
		path: mainMenu.reports.subMenu.worktime.path,
		element: <Aside />,
		exact: true,
	},
	{
		path: mainMenu.inventarization.path,
		element: <Aside />,
		exact: true,
	},
	{
		path: 'profile',
		element: <Aside />,
		exact: true,
	},
	{
		path: mainMenu.cameras.path,
		element: <Aside />,
		exact: true,
	},
	{
		path: mainMenu.security.path,
		element: <Aside />,
		exact: true,
	},
];

const library = [
	{
		path: libraryMenu.library.subMenu.places.path,
		element: <Aside />,
		exact: true,
	},
];

const administration = [
	{
		path: administrationMenu.administration.subMenu.administrationUsers.path,
		element: <Aside />,
		exact: true,
	},
	{
		path: `${administrationMenu.administration.subMenu.administrationUsers.path}/:hash/*`,
		element: <Aside />,
		exact: true,
	},
	{
		path: administrationMenu.administration.subMenu.administrationApi.path,
		element: <Aside />,
		exact: true,
	},
];

const asides = [...auth, ...main, ...library, ...administration];

export default asides;
