import React from 'react';

import Icon from '../../components/icon/Icon';

import showNotification from '../../components/extras/showNotification';

const notification = ({ icon, color, title, text }) => {
	showNotification(
		<span className='d-flex align-items-center'>
			{icon && <Icon icon={icon} size='lg' className='me-1' />}
			<span>{title}</span>
		</span>,
		text,
		color,
	);
};

export default notification;
