import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const AvailableContext = createContext(null);

export const AvailableContextProvider = ({ children }) => {
	const [isAvailable, setIsAvailable] = useState(true);
	const value = useMemo(
		() => ({
			isAvailable,
			setIsAvailable,
		}),
		[isAvailable, setIsAvailable],
	);

	return <AvailableContext.Provider value={value}>{children}</AvailableContext.Provider>;
};

export const useAvailableContext = () => useContext(AvailableContext);

AvailableContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
