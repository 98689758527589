import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import headers from '../../routes/headerRoutes';

const SentryRoutes = withSentryReactRouterV6Routing(Routes);
const HeaderRoutes = () => {
	return (
		<SentryRoutes>
			{headers.map((page) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Route key={page.path} {...page} />
			))}
		</SentryRoutes>
	);
};

export default HeaderRoutes;
