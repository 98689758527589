import React, { createContext, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const SearchContext = createContext(null);

export const SearchContextProvider = ({ children }) => {
	const [search, setSearch] = useState('');
	const value = useMemo(
		() => ({
			search,
			setSearch,
		}),
		[search, setSearch],
	);

	return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export const useSearchContext = () => useContext(SearchContext);

SearchContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
