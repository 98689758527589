import React, { useContext, useEffect, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useToasts } from 'react-toast-notifications';

import Content from '../Content/Content';
import WrapperOverlay from './WrapperOverlay';
import HeaderRoutes from '../Header/HeaderRoutes';
import FooterRoutes from '../Footer/FooterRoutes';
import Toasts from '../../components/bootstrap/Toasts';
import ThemeContext from '../../contexts/themeContext';
import { SearchContextProvider } from '../../contexts/searchContext';
import { useDevContext } from '../../contexts/devContext';
import FallBack from '../Fallback/Fallback';

export const WrapperContainer = ({ children, className, ...props }) => {
	const { rightPanel } = useContext(ThemeContext);
	return (
		<div
			className={classNames('wrapper', { 'wrapper-right-panel-active': rightPanel }, className)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}>
			{children}
		</div>
	);
};
WrapperContainer.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
WrapperContainer.defaultProps = {
	className: null,
};

const Wrapper = () => {
	const { isDev } = useDevContext();
	const { lastJsonMessage, readyState } = useWebSocket(
		isDev ? 'wss://wss-test.lgprk.ru' : process.env.REACT_APP_WEBSOCKET_URL,
		{
			shouldReconnect: () => true,
			reconnectAttempts: Infinity,
			reconnectInterval: 10000,
			share: true,
			onOpen: (event) => {
				console.log(event);
			},
			onClose: (event) => {
				console.log(event);
			},
			onError: (event) => {
				console.log(event);
			},
		},
	);
	const { addToast } = useToasts();

	useEffect(() => {
		if (readyState === ReadyState.OPEN && lastJsonMessage && lastJsonMessage.type === 'result') {
			addToast(
				<Toasts
					title={lastJsonMessage.title}
					icon={lastJsonMessage.success ? 'Success' : 'Error'}
					iconColor={lastJsonMessage.success ? 'success' : 'danger'}
					isDismiss={false}>
					<a href={lastJsonMessage.link} download>
						Скачать файл
					</a>
				</Toasts>,
				{
					autoDismiss: true,
				},
			);
		}
	}, [addToast, lastJsonMessage, readyState]);

	return (
		<>
			<WrapperContainer>
				<SearchContextProvider>
					<HeaderRoutes />
					<ErrorBoundary FallbackComponent={FallBack}>
						<Content />
					</ErrorBoundary>
					<FooterRoutes />
				</SearchContextProvider>
			</WrapperContainer>
			<WrapperOverlay />
		</>
	);
};

export default Wrapper;
