import PropTypes from 'prop-types';
import { string, type, nullable, optional, union, number } from 'superstruct';

export const StatusData = type({
	id: optional(union([string(), number()])),
	name: string(),
	hash: string(),
	value: nullable(string()),
});

const statusType = PropTypes.shape({
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string,
	hash: PropTypes.string,
	value: PropTypes.string,
});

export default statusType;
