import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import CommonHeaderChat from './CommonHeaderChat';
import useDarkMode from '../../../hooks/useDarkMode';
import Search from '../../../components/SearchHeader';
import SearchModal from '../../../components/SearchModal';
import Popovers from '../../../components/bootstrap/Popovers';
import Button from '../../../components/bootstrap/Button';
import Checks from '../../../components/bootstrap/forms/Checks';
import ReactSelect from '../../../components/extras/ReactSelect';

import { useAuth } from '../../../contexts/authContext';
import { useDevContext } from '../../../contexts/devContext';
import useFetch from '../../../hooks/useFetch';

const SearchHeader = ({ isDisable, searchComponent }) => {
	const { darkModeStatus } = useDarkMode();
	const auth = useAuth();
	const { isDev, setIsDev } = useDevContext();
	const { sendData } = useFetch();
	const [, setSearchParams] = useSearchParams();

	const onUserSelect = useCallback(
		(value) => {
			if (value) {
				const formData = new FormData();
				formData.set('token', auth.token);
				formData.set('user', value.hash);
				setIsDev(false);
				sendData('/admin/login/', formData).then((responseData) => {
					if (responseData?.success === 'Auth ok' && responseData?.session_id) {
						setSearchParams({
							token: responseData.session_id,
						});
					}
				});
			}
		},
		[auth.token, sendData, setIsDev, setSearchParams],
	);

	return (
		<Header>
			<HeaderLeft>
				<div className='d-flex align-items-center'>
					<div className='row g-4'>
						<div className='col-md-auto'>
							<div
								className={classNames({
									'text-dark': !darkModeStatus,
								})}>
								{searchComponent === 'input' && <Search isDisable={isDisable} />}
								{searchComponent === 'modal' && isDev ? <SearchModal /> : null}
							</div>
						</div>
					</div>
				</div>
			</HeaderLeft>
			<CommonHeaderRight
				beforeChildren={
					auth.user?.user.isAdmin ? (
						<>
							<div className='col-auto pt-3'>
								<Popovers trigger='hover' title='Режим разработки'>
									<Checks
										type='switch'
										checked={isDev}
										onChange={(event) => setIsDev(event.target.checked)}
									/>
								</Popovers>
							</div>
							<div className='col-auto'>
								<Popovers
									trigger='click'
									desc={<ReactSelect name='selectUser' onSetValue={onUserSelect} />}>
									<Button size='lg' icon='DeveloperMode' title='Зайти под пользователем' />
								</Popovers>
							</div>
						</>
					) : null
				}
				afterChildren={<CommonHeaderChat />}
			/>
		</Header>
	);
};

SearchHeader.propTypes = {
	isDisable: PropTypes.bool,
	searchComponent: PropTypes.oneOf(['input', 'modal']),
};

SearchHeader.defaultProps = {
	isDisable: false,
	searchComponent: 'input',
};

export default SearchHeader;
