import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
	BrowserRouter as Router,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import { init as sentryInit, reactRouterV6Instrumentation } from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import { Dedupe } from '@sentry/integrations';

import './styles/styles.scss';
import App from './App/App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { DevContextProvider } from './contexts/devContext';

const isDev = JSON.parse(sessionStorage.getItem('isDev'));
const isLoc = (process.env.REACT_APP_DOMAIN_NAME === 'localhost');

if (!isLoc && !isDev) {
	sentryInit({
		dsn: process.env.REACT_APP_SENTRY_URL,
		integrations: [
			new BrowserTracing({
				routingInstrumentation: reactRouterV6Instrumentation(
					useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes,
				),
			}),
			new Dedupe(),
		],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}

ReactDOM.render(
	<Router>
		<React.StrictMode>
			<ThemeContextProvider>
				<DevContextProvider>
					<App />
				</DevContextProvider>
			</ThemeContextProvider>
		</React.StrictMode>
	</Router>,
	document.getElementById('root'),
);

// if ('Notification' in window) {
// 	serviceWorkerRegistration.checkPermissions();
// 	serviceWorkerRegistration.register();
// 	serviceWorkerRegistration.unregister();
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

if (!isLoc && !isDev) {
	reportWebVitals();
}
