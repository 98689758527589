import React, { useContext, useState /* , useEffect, useMemo, useCallback */ } from 'react';
import PropTypes from 'prop-types';
// import { useGeolocation } from 'react-use';
// import classNames from 'classnames';

import Button from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Alert from '../../../components/bootstrap/Alert';
// import Dropdown, {
// 	DropdownItem,
// 	DropdownMenu,
// 	DropdownToggle,
// } from '../../../components/bootstrap/Dropdown';
// import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
// import LANG, { getLangWithKey } from '../../../lang';
// import showNotification from '../../../components/extras/showNotification';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
// import Spinner from '../../../components/bootstrap/Spinner';
import USERS from '../../../common/data/userDummyData';

// eslint-disable-next-line react/prop-types
const CommonHeaderRight = ({ beforeChildren, afterChildren }) => {
	const { darkModeStatus } = useDarkMode();
	// const geolocation = useGeolocation({ enableHighAccuracy: true, timeout: 60000 });
	// const [weather, setWeather] = useState(null);
	// const [isDisplayWeather, setIsDisplayWeather] = useState(true);
	/*
	const weatherIcon = useMemo(() => {
		if (weather) {
			const { id } = weather.weather[0];
			switch (true) {
				case id >= 200 && id < 300:
					return {
						icon: 'CloudLightningRain',
						color: darkModeStatus ? 'light' : 'dark',
					};

				case id >= 300 && id < 400:
					return {
						icon: 'CloudDrizzle',
						color: darkModeStatus ? 'light' : 'dark',
					};

				case id >= 500 && id < 600:
					return {
						icon: 'CloudRain',
						color: darkModeStatus ? 'light' : 'dark',
					};

				case id >= 600 && id < 700:
					return {
						icon: 'CloudSnow',
						color: 'info',
					};

				case id >= 700 && id < 800:
					return {
						icon: 'CloudFog',
						color: 'muted',
					};

				case id === 800:
					return {
						icon: 'Sun',
						color: 'warning',
					};

				case id > 800 && id < 900:
					return {
						icon: 'Clouds',
						color: darkModeStatus ? 'light' : 'dark',
					};

				default:
					return {
						icon: '',
						color: darkModeStatus ? 'light' : 'dark',
					};
			}
		}
		return null;
	}, [weather, darkModeStatus]);

	const sunrise = useMemo(() => (weather ? new Date(weather.sys.sunrise * 1000) : null), [weather]);
	const sunset = useMemo(() => (weather ? new Date(weather.sys.sunset * 1000) : null), [weather]);

	const getWeather = useCallback(
		(controller) => {
			fetch(
				`https://api.openweathermap.org/data/2.5/weather?lat=${geolocation.latitude}&lon=${geolocation.longitude}&lang=ru&units=metric&appid=191457c8735e283e8faf4c3f6c12eb45`,
				{ signal: controller.signal },
			)
				.then((response) => (response.ok ? response.json() : Promise.reject(response)))
				.then((data) => {
					if (data) {
						setIsDisplayWeather(true);
						setWeather(data);
					}
				})
				.catch(() => {
					setIsDisplayWeather(false);
				});
		},
		[geolocation.latitude, geolocation.longitude],
	);

	useEffect(() => {
		const controller = new AbortController();
		let interval;
		if (geolocation.error) {
			setIsDisplayWeather(false);
		} else if (geolocation.latitude && geolocation.longitude) {
			getWeather(controller);
			interval = setInterval(() => getWeather(controller), 60 * 60 * 1000);
		}

		return () => {
			controller.abort();
			clearInterval(interval);
		};
	}, [geolocation.latitude, geolocation.longitude, geolocation.error, getWeather]); */

	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}
				{/* Tour Modal */}
				{/* {localStorage.getItem('tourModalStarted') === 'shown' && (
					<div className='col-auto position-relative'>
						<Popovers trigger='hover' desc='Start the "Facit" tour'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon='Tour'
								onClick={() => setIsOpen(true)}
								aria-label='Start the "Facit" tour'
							/>
						</Popovers>
						<Icon
							icon='Circle'
							className={classNames(
								'position-absolute start-65',
								'text-danger',
								'animate__animated animate__heartBeat animate__infinite animate__slower',
							)}
						/>
					</div>
				)} */}

				{/* Погода }
				{isDisplayWeather ? (
					<div className='col-auto'>
						<Popovers
							trigger='hover'
							title={weather ? `Погода сейчас в ${weather.name}` : ''}
							desc={
								weather ? (
									<>
										<div className='row mb-2'>
											<div className='col-6'>
												<h1 className='text-center'>{Math.round(weather.main.temp)}°C</h1>
												<div className='text-wrap text-center'>
													Ощущается как {Math.round(weather.main.feels_like)}°C
												</div>
											</div>
											<div className='col-6'>
												<div className='text-center mb-2'>
													<Icon icon={weatherIcon.icon} color={weatherIcon.color} size='3x' />
												</div>
												<div className='text-center text-wrap'>
													{weather.weather[0].description}
												</div>
											</div>
										</div>
										<div className='row g-4 text-muted'>
											<div className='col-4'>
												<div>
													<Icon icon='Sunrise' size='lg' className='me-1' />
													{sunrise.getHours()}:{sunrise.getMinutes()}
												</div>
												<div>
													<Icon icon='Sunset' size='lg' className='me-1' />
													{sunset.getHours()}:{sunset.getMinutes()}
												</div>
											</div>
											<div className='col-4'>
												<div>
													<Icon icon='ThermometerHigh' size='lg' className='me-1' />
													{Math.round(weather.main.temp_max)}°C
												</div>
												<div>
													<Icon icon='ThermometerLow' size='lg' className='me-1' />
													{Math.round(weather.main.temp_min)}°C
												</div>
											</div>
											<div className='col-4'>
												<div>
													<Icon icon='Droplet' size='lg' className='me-1' />
													{Math.round(weather.main.humidity)}%
												</div>
												<div>
													<Icon icon='Wind' size='lg' className='me-1' />
													{Math.round(weather.wind.speed)} м/с
												</div>
											</div>
										</div>
									</>
								) : (
									<div className='text-center'>
										<Spinner isGrow />
									</div>
								)
							}>
							<div
								className='btn btn-lg btn-hover-shadow btn-only-icon'
								style={{ cursor: 'default' }}>
								{weather ? (
									<Icon icon={weatherIcon.icon} color={weatherIcon.color} className='btn-icon' />
								) : (
									<Spinner isSmall inButton='onlyIcon' isGrow />
								)}
							</div>
						</Popovers>
					</div>
				) : null}
				{ */}

				{/* Dark Mode */}
				{/* <div className='col-auto'>
					<Popovers trigger='hover' desc='Тёмная / Светлая тема'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							className='btn-only-icon'
							data-tour='dark-mode'
							isDisable>
							<Icon
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								color={darkModeStatus ? 'info' : 'warning'}
								className='btn-icon'
							/>
						</Button>
					</Popovers>
				</div> */}

				{/*	Full Screen */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Полный экран'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle dark mode'
						/>
					</Popovers>
				</div>

				<div className='col-auto'>
					<Popovers trigger='hover' desc='На старую версию портала'>
						{
							// eslint-disable-next-line react/jsx-props-no-spreading
							<Button {...styledBtn} tag='a' href='https://lgprk.ru'>
								<img src='https://lgprk.ru/assets/icon/favicon.png' alt='Логотип Ориентир' />
							</Button>
						}
					</Popovers>
				</div>

				{/* Lang Selector */}
				{/* <div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							{typeof getLangWithKey(i18n.language)?.icon === 'undefined' ? (
								<Button
									{...styledBtn}
									className='btn-only-icon'
									aria-label='Change language'
									data-tour='lang-selector'>
									<Spinner isSmall inButton='onlyIcon' isGrow />
								</Button>
							) : (
								<Button
									{...styledBtn}
									icon={getLangWithKey(i18n.language)?.icon}
									aria-label='Change language'
									data-tour='lang-selector'
								/>
							)}
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
							{Object.keys(LANG).map((i) => (
								<DropdownItem key={LANG[i].lng}>
									<Button
										icon={LANG[i].icon}
										onClick={() => changeLanguage(LANG[i].lng)}>
										{LANG[i].text}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div> */}

				{/* Quick Panel */}
				{/* <div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button {...styledBtn} icon='Tune' aria-label='Quick menu' />
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd size='lg' className='py-0 overflow-hidden'>
							<div className='row g-0'>
								<div
									className={classNames(
										'col-12',
										'p-4',
										'd-flex justify-content-center',
										'fw-bold fs-5',
										'text-info',
										'border-bottom border-info',
										{
											'bg-l25-info': !darkModeStatus,
											'bg-lo25-info': darkModeStatus,
										},
									)}>
									Quick Panel
								</div>
								<div
									className={classNames(
										'col-6 p-4 transition-base cursor-pointer bg-light-hover',
										'border-end border-bottom',
										{ 'border-dark': darkModeStatus },
									)}>
									<div className='d-flex flex-column align-items-center justify-content-center'>
										<Icon icon='Public' size='3x' color='info' />
										<span>Dealers</span>
										<small className='text-muted'>Options</small>
									</div>
								</div>
								<div
									className={classNames(
										'col-6 p-4 transition-base cursor-pointer bg-light-hover',
										'border-bottom',
										{ 'border-dark': darkModeStatus },
									)}>
									<div className='d-flex flex-column align-items-center justify-content-center'>
										<Icon icon='Upcoming' size='3x' color='success' />
										<span>Inbox</span>
										<small className='text-muted'>Configuration</small>
									</div>
								</div>
								<div
									className={classNames(
										'col-6 p-4 transition-base cursor-pointer bg-light-hover',
										'border-end',
										{ 'border-dark': darkModeStatus },
									)}>
									<div className='d-flex flex-column align-items-center justify-content-center'>
										<Icon icon='Print' size='3x' color='danger' />
										<span>Print</span>
										<small className='text-muted'>Settings</small>
									</div>
								</div>
								<div className='col-6 p-4 transition-base cursor-pointer bg-light-hover'>
									<div className='d-flex flex-column align-items-center justify-content-center'>
										<Icon icon='ElectricalServices' size='3x' color='warning' />
										<span>Power</span>
										<small className='text-muted'>Mode</small>
									</div>
								</div>
							</div>
						</DropdownMenu>
					</Dropdown>
				</div> */}

				{/*	Notifications */}
				{/* <div className='col-auto'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon='Notifications'
						onClick={() => setOffcanvasStatus(true)}
						aria-label='Notifications'
					/>
				</div> */}
				{afterChildren}
			</div>

			<OffCanvas
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={offcanvasStatus}
				setOpen={setOffcanvasStatus}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle id='offcanvasExampleLabel'>Уведомления</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					{USERS.CHLOE.notifications.map((notification) => (
						<Alert
							icon='Info'
							isLight
							color={notification.type}
							className='flex-nowrap'
							key={notification.id}>
							{notification.content}
						</Alert>
					))}
					{/* <Alert icon='ThumbUp' isLight color='warning' className='flex-nowrap'>
						New products added to stock.
					</Alert>
					<Alert icon='Inventory2' isLight color='danger' className='flex-nowrap'>
						There are products that need to be packaged.
					</Alert>
					<Alert icon='BakeryDining' isLight color='success' className='flex-nowrap'>
						Your food order is waiting for you at the consultation.
					</Alert>
					<Alert icon='Escalator' isLight color='primary' className='flex-nowrap'>
						Escalator will turn off at 6:00 pm.
					</Alert> */}
				</OffCanvasBody>
			</OffCanvas>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
