import React, { Children, cloneElement, forwardRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { randomColor } from '../helpers/helpers';
import Popovers from './bootstrap/Popovers';
import useDarkMode from '../hooks/useDarkMode';
import Icon from './icon/Icon';
import { useDevContext } from '../contexts/devContext';
import AvatarPlaceholder from '../assets/img/avatar.png';

export const AvatarGroup = ({ className, children, size, count }) => {
	const { darkModeStatus } = useDarkMode();

	return (
		<div className={classNames('avatar-group', className)}>
			<div className='avatar-container text-nowrap'>
				{Children.map(children, (child, index) =>
					index < count ? (
						<div className='position-relative d-inline-block'>
							{cloneElement(child, {
								className: child.props.isBlocked ? 'opacity-50' : null,
								borderColor: darkModeStatus ? 'dark' : 'white',
								border: 2,
								color: child.props.color || randomColor(),
								size,
							})}
							{child.props.isBlocked ? (
								<Popovers desc='Заблокирован' trigger='hover'>
									<span
										className='position-absolute top-50 start-30 translate-middle'
										style={{
											zIndex: 4,
										}}>
										<Icon icon='XLg' color='light' size='3x' />
									</span>
								</Popovers>
							) : null}
						</div>
					) : null,
				)}
			</div>
			{children.length > count && (
				<Popovers
					desc={Children.map(children, (child, index) =>
						index >= count ? (
							<>
								{child.props.userName}
								<br />
							</>
						) : null,
					)}
					trigger='hover'>
					<div className='avatar-more' style={{ width: size, height: size }}>
						+{children.length - count}
					</div>
				</Popovers>
			)}
		</div>
	);
};

AvatarGroup.displayName = 'AvatarGroup';

AvatarGroup.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	size: PropTypes.number,
	count: PropTypes.number,
};

AvatarGroup.defaultProps = {
	className: null,
	size: 32,
	count: 5,
};

export const AvatarStorage = forwardRef(
	(
		{
			images,
			className,
			size,
			rounded,
			shadow,
			color,
			border,
			borderColor,
			userName,
			isOnline,
			isReply,
			isBlocked,
			...props
		},
		ref,
	) => {
		const { isDev } = useDevContext();
		const image = useMemo(() => {
			const avatar = images?.find((item) => item.type === 'avatar');
			if (avatar?.storage) {
				switch (avatar.storage) {
					case 'old':
						return `https://lgprk.ru${avatar.path}`;

					case 'local':
						return `${isDev ? process.env.REACT_APP_API_TEST_URL : process.env.REACT_APP_API_URL}${
							avatar.path
						}`;

					default:
						return avatar.path;
				}
			}
			return AvatarPlaceholder;
		}, [images, isDev]);

		return (
			<Avatar
				ref={ref}
				src={image}
				className={className}
				size={size}
				rounded={rounded}
				shadow={shadow}
				color={color}
				border={border}
				borderColor={borderColor}
				userName={userName}
				isOnline={isOnline}
				isReply={isReply}
				isBlocked={isBlocked}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			/>
		);
	},
);

AvatarStorage.displayName = 'AvatarStorage';

AvatarStorage.propTypes = {
	images: PropTypes.arrayOf(
		PropTypes.shape({
			created_at: PropTypes.string,
			path: PropTypes.string,
			storage: PropTypes.string,
			type: PropTypes.string,
			updated_at: PropTypes.string,
		}),
	),
	className: PropTypes.string,
	size: PropTypes.number,
	rounded: PropTypes.oneOf([
		'default',
		0,
		1,
		2,
		3,
		'bottom',
		'top',
		'circle',
		'end',
		'start',
		'pill',
	]),
	color: PropTypes.oneOf([
		null,
		'primary',
		'secondary',
		'success',
		'info',
		'warning',
		'danger',
		'light',
		'dark',
		'link',
		'brand',
		'brand-two',
		'storybook',
	]),
	shadow: PropTypes.oneOf([null, 'none', 'sm', 'default', 'lg']),
	border: PropTypes.oneOf([null, 0, 1, 2, 3, 4, 5]),
	borderColor: PropTypes.oneOf([
		null,
		'primary',
		'secondary',
		'success',
		'info',
		'warning',
		'danger',
		'light',
		'dark',
		'link',
		'brand',
		'brand-two',
		'storybook',
		'white',
	]),
	userName: PropTypes.string,
	isOnline: PropTypes.bool,
	isReply: PropTypes.bool,
	isBlocked: PropTypes.bool,
};

AvatarStorage.defaultProps = {
	images: null,
	className: null,
	size: 128,
	rounded: 'circle',
	color: 'primary',
	shadow: null,
	border: null,
	borderColor: null,
	userName: null,
	isOnline: false,
	isReply: false,
	isBlocked: false,
};

const Avatar = forwardRef(
	(
		{
			srcSet,
			src,
			className,
			size,
			rounded,
			shadow,
			color,
			border,
			borderColor,
			userName,
			isOnline, // Not used
			isReply, // Not used
			isBlocked,
			...props
		},
		ref,
	) => {
		const { darkModeStatus } = useDarkMode();
		const [hasError, setHasError] = useState(false);

		const _Inner = (
			<img
				ref={ref}
				className={classNames(
					'avatar',
					{
						[`rounded${rounded !== 'default' ? `-${rounded}` : ''}`]: rounded,
						'rounded-0': rounded === 0 || rounded === '0',
						[`shadow${shadow !== 'default' ? `-${shadow}` : ''}`]: !!shadow,
						border: !!border,
						[`border-${border}`]: !!border,
						[`border-${borderColor}`]: borderColor,
					},
					`bg-l${darkModeStatus ? 'o' : ''}25-${color}`,
					className,
				)}
				srcSet={srcSet}
				src={hasError ? AvatarPlaceholder : src}
				alt='Аватар'
				width={size}
				height={size}
				onError={() => setHasError(true)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			/>
		);

		if (userName) {
			return (
				<Popovers desc={userName} trigger='hover'>
					{_Inner}
				</Popovers>
			);
		}
		return _Inner;
	},
);

Avatar.displayName = 'Avatar';

Avatar.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
	className: PropTypes.string,
	size: PropTypes.number,
	rounded: PropTypes.oneOf([
		'default',
		0,
		1,
		2,
		3,
		'bottom',
		'top',
		'circle',
		'end',
		'start',
		'pill',
	]),
	color: PropTypes.oneOf([
		null,
		'primary',
		'secondary',
		'success',
		'info',
		'warning',
		'danger',
		'light',
		'dark',
		'link',
		'brand',
		'brand-two',
		'storybook',
	]),
	shadow: PropTypes.oneOf([null, 'none', 'sm', 'default', 'lg']),
	border: PropTypes.oneOf([null, 0, 1, 2, 3, 4, 5]),
	borderColor: PropTypes.oneOf([
		null,
		'primary',
		'secondary',
		'success',
		'info',
		'warning',
		'danger',
		'light',
		'dark',
		'link',
		'brand',
		'brand-two',
		'storybook',
		'white',
	]),
	userName: PropTypes.string,
	isOnline: PropTypes.bool,
	isReply: PropTypes.bool,
	isBlocked: PropTypes.bool,
};

Avatar.defaultProps = {
	srcSet: null,
	className: null,
	size: 128,
	rounded: 'circle',
	color: 'primary',
	shadow: null,
	border: null,
	borderColor: null,
	userName: null,
	isOnline: false,
	isReply: false,
	isBlocked: false,
};

export default Avatar;
