const surfing = {
	id: 1,
	name: 'Север-1',
	icon: 'Surfing',
	color: 'info',
};
const kiteSurfing = {
	id: 2,
	name: 'Север-2',
	icon: 'Kitesurfing',
	color: 'danger',
};
const tennis = {
	id: 3,
	name: 'Север-4',
	icon: 'SportsTennis',
	color: 'success',
};
const kayaking = {
	id: 4,
	name: 'Домодедово',
	icon: 'Kayaking',
	color: 'info',
};
const handball = {
	id: 5,
	name: 'Лента СПБ',
	icon: 'SportsHandball',
	color: 'warning',
};
const iceSkating = {
	id: 6,
	name: 'Алабушево',
	icon: 'IceSkating',
	color: 'info',
};
const snowboarding = {
	id: 7,
	name: 'Рига',
	icon: 'Snowboarding',
	color: 'warning',
};
const volleyball = {
	id: 8,
	name: 'Лемболово',
	icon: 'SportsVolleyball',
	color: 'warning',
};
const cricket = {
	id: 9,
	name: 'Офис СПБ',
	icon: 'SportsCricket',
	color: 'success',
};
const yoga = {
	id: 10,
	name: 'Калининград',
	icon: 'SelfImprovement',
	color: 'success',
};
const hiking = {
	id: 11,
	name: 'ВТБ Косино',
	icon: 'Hiking',
	color: 'danger',
};
const football = {
	id: 12,
	name: 'Глория Джинс Гривно',
	icon: 'SportsFootball',
	color: 'success',
};

const SERVICES = {
	SURFING: surfing,
	KITE_SURFING: kiteSurfing,
	TENNIS: tennis,
	KAYAKING: kayaking,
	HANDBALL: handball,
	ICE_SKATING: iceSkating,
	SNOWBOARDING: snowboarding,
	VOLLEYBALL: volleyball,
	CRICKET: cricket,
	YOGA: yoga,
	HIKING: hiking,
	FOOTBALL: football,
};

export function getServiceDataWithServiceName(serviceName) {
	return SERVICES[Object.keys(SERVICES).filter((f) => SERVICES[f].name.toString() === serviceName)];
}

export default SERVICES;
