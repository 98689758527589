import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { ToastProvider } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import { ErrorBoundary } from '@sentry/react';

import ThemeContext from '../contexts/themeContext';
import { AuthContextProvider } from '../contexts/authContext';
import { LoadingContextProvider } from '../contexts/loadingContext';
import { AvailableContextProvider } from '../contexts/availableContext';

import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import AsideRoutes from '../layout/Aside/AsideRoutes';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import FallBack from '../layout/Fallback/Fallback';
import { useDevContext } from '../contexts/devContext';

const App = () => {
	getOS();

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	const isLoc = (process.env.REACT_APP_DOMAIN_NAME === 'localhost');
	const { isDev } = useDevContext();

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	return (isLoc || isDev) ? (
		<ThemeProvider theme={theme}>
			<ToastProvider components={{ ToastContainer, Toast }}>
				<TourProvider
					steps={steps}
					styles={styles}
					showNavigation={false}
					showBadge={false}
					disableInteraction
					scrollSmooth>
					<div
						ref={ref}
						className='app'
						style={{
							backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
							zIndex: fullScreenStatus && 1,
							overflow: fullScreenStatus && 'scroll',
						}}>
						<AuthContextProvider>
							<LoadingContextProvider>
								<AvailableContextProvider>
									<AsideRoutes />
									<Wrapper />
								</AvailableContextProvider>
							</LoadingContextProvider>
						</AuthContextProvider>
					</div>
					<Portal id='portal-notification'>
						<ReactNotifications />
					</Portal>
				</TourProvider>
			</ToastProvider>
		</ThemeProvider>
	) : (
		<ErrorBoundary fallback={FallBack} showDialog>
			<ThemeProvider theme={theme}>
				<ToastProvider components={{ ToastContainer, Toast }}>
					<TourProvider
						steps={steps}
						styles={styles}
						showNavigation={false}
						showBadge={false}
						disableInteraction
						scrollSmooth>
						<div
							ref={ref}
							className='app'
							style={{
								backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
								zIndex: fullScreenStatus && 1,
								overflow: fullScreenStatus && 'scroll',
							}}>
							<AuthContextProvider>
								<LoadingContextProvider>
									<AvailableContextProvider>
										<AsideRoutes />
										<Wrapper />
									</AvailableContextProvider>
								</LoadingContextProvider>
							</AuthContextProvider>
						</div>
						<Portal id='portal-notification'>
							<ReactNotifications />
						</Portal>
					</TourProvider>
				</ToastProvider>
			</ThemeProvider>
		</ErrorBoundary>
	);
};

export default App;
